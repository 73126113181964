.header-container {
  overflow: hidden;
  background-color: transparent;
}

.font-cl a {
  color: #92cd86;
  font-size: 1.4rem;
  margin-right: 2rem;
  transition: 0.5s;
}

.font-cl a:hover {
  color: var(--color-font);
}

.nav-links {
  font-weight: 400;
  font-size: 20px;
}

.logo_spin {
  animation: pms-logo-spin infinite 10s linear;
  -webkit-animation: pms-logo-spin infinite 10s linear;
}

.navbar-slide {
  animation: fadeIn 300ms;
  -webkit-animation: fadeIn 300ms;
}

@-moz-keyframes pms-logo-spin {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes pms-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

/** Spin the logo **/
@keyframes pms-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/** Navbar slide in **/

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
