
@keyframes move-twink-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -10000px 5000px;
    }
}

@keyframes move-clouds-back {
    from {
        background-position: 0 0;
    }
    to {
        background-position: 10000px 0;
    }
}

.background-container {
    height: max-content;
}
.stars {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: inherit;
    z-index: -3;
}



.stars {
    background: #000 url(/src/assets/img/stars.png) repeat top center;
}

.twinkling {
    background: transparent url(/src/assets/img/twinkling.png) repeat top center;
    animation: move-twink-back 200s linear infinite;
}


@media (max-width: 940px){

}


