* {
  /** General Color **/
  --color-bg-dark: #121212;
  --color-bg-light-dark: #181818;
  --color-bg-light-grey: #404040;
  --color-bg-dark-grey: #282828;
  --color-bg-white: #FFFFFF;
  --color-bg-white-grey: #b3b3b3;
  --color-bg-element: #101820ff;
  --color-font: var(--color-bg-white-grey);
  --color-light-green: #92cd86;
  --color-font-white: #fff;

}

.create-wallet-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 60%;
    background-color: var(--color-bg-dark);
    color: var(--color-font);
    font-family: Arial, sans-serif;
    margin-left: 20%;
  }
  
  h2 {
    font-size: 36px;
    margin-bottom: 24px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  label {
    font-size: 24px;
    margin-bottom: 8px;
  }
  
  input {
    padding: 8px;
    margin-bottom: 16px;
    width: 100%;
    font-size: 18px;
    border: none;
    border-radius: 4px;
  }
  
  input:focus {
    outline: none;
  }
  
  button {
    background-color: transparent;
    color: var(--color-font-white);
    font-size: 18px;
    padding: 6px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    margin-bottom: 15px;
  }
  
  button:hover {
    background-color: #841ed7;
  }
  
  input:invalid {
    border: 2px solid #ff4136;
  }

  .error-box {
    background-color: #ff4136;
    color: var(--color-font-white);
    font-size: 16px;
    padding: 8px;
    margin-bottom: 16px;
    border-radius: 4px;
  }

  /* Media Queries for iPhone 12 Pro */
  @media only screen and (max-width: 390px) {
    .create-wallet-container {
      width: 90%;
      margin-left: 5%;
    }
    
    h2 {
      font-size: 28px;
      margin-bottom: 16px;
    }
    
    label {
      font-size: 20px;
      margin-bottom: 6px;
    }
    
    input {
      font-size: 16px;
    }
    
    button {
      font-size: 16px;
      padding: 4px 12px;
      margin-bottom: 10px;
    }
    
    .error-box {
      font-size: 14px;
      margin-bottom: 10px;
    }
  }
