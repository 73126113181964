/* SeedPhrase.css */

.seed-phrase-container {
  text-align: center;
}

.seed-phrase-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-gap: 30px;
  margin-top: 20px;
}

.seed-phrase-word {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  background-color: rgb(19, 19, 222);
  color: #ffffff;
}

.seed-phrase-btn-container {
  margin-top: 30px;
}

.seed-phrase-btn {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  cursor: pointer;
}

.seed-phrase-btn:hover {
  background-color: #0062cc;
}

/* Media queries for iPhone 12 Pro */

@media only screen and (max-device-width: 390px) {

  .seed-phrase-grid {
    grid-template-columns: repeat(auto-fill, minmax(75px, 1fr));
    grid-gap: 20px;
    margin-top: 15px;
  }

  .seed-phrase-word {
    height: 50px;
    font-size: 14px;
    padding: 5px;
  }

  .seed-phrase-btn-container {
    margin-top: 20px;
  }

  .seed-phrase-btn {
    margin: 0 5px;
    padding: 8px 15px;
    font-size: 14px;
  }
}
