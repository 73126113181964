/* General Styling */
.demo-section {
  padding: 50px;
  background-color: rgba(14, 11, 227, 0.0); /* Subtle transparency */
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(38, 163, 142, 0.0);
  color: #ffffff;
  max-width: 800px;
  margin: 0 auto; /* Center the container */
  text-align: center; /* Center all text */
}

.demo-section h2,
.demo-section h3 {
  font-family: 'Arial', sans-serif;
  color: #5af0b9;
  margin-bottom: 24px;
  text-align: center;
  font-size: 28px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.mint-section,
.map-section {
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center form elements */
}

.mint-section-container,
.map-section-container {
  width: 100%;
  max-width: 600px; /* Constrain the width of the form, header, and text */
}

.mint-section h3,
.mint-section p,
.map-section p {
  width: 100%; /* Ensure header and text are the same width as inputs */
  text-align: left; /* Align text to the left for better flow */
  margin-bottom: 24px;
}

.mint-section label,
.map-section label {
  font-size: 16px;
  color: #ffffff;
  display: block;
  margin-bottom: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: left; /* Align labels to the left for better readability */
}

.mint-section input,
.mint-section textarea,
.map-section input,
.map-section textarea {
  width: 100%;
  max-width: 600px; /* Limit the width of form elements */
  padding: 16px;
  margin-bottom: 24px;
  border: none;
  border-radius: 8px;
  font-family: 'Arial', sans-serif;
  background-color: rgba(47, 49, 69, 0.85); /* Semi-transparent background for inputs */
  color: #ffffff;
  font-size: 18px; /* Larger font size */
  height: 50px; /* Fixed height for inputs */
  transition: box-shadow 0.3s ease;
  text-align: left; /* Align text input to the left */
}

.mint-section textarea,
.map-section textarea {
  height: 50px; /* Make textarea height consistent with inputs */
}

.mint-section input:focus,
.mint-section textarea:focus,
.map-section input:focus,
.map-section textarea:focus {
  outline: none;
  box-shadow: 0 0 12px rgba(90, 240, 185, 0.5);
}

.mint-section input::placeholder,
.mint-section textarea::placeholder,
.map-section input::placeholder,
.map-section textarea::placeholder {
  color: #bbb;
}

.mint-section button,
.map-section button {
  background-color: #5af0b9;
  color: #fff;
  border: none;
  padding: 0 28px; /* Remove vertical padding */
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
  font-size: 18px;
  display: flex; /* Use flexbox to center text */
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  width: 100%;
  max-width: 600px; /* Ensure the button is the same width as inputs */
  height: 50px; /* Same height as input fields */
  margin-bottom: 24px; /* Same spacing as inputs */
  text-align: center; /* Center text horizontally */
}

.mint-section button:hover,
.map-section button:hover {
  background-color: #50dca1;
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(90, 240, 185, 0.3);
}

.alert {
  margin-top: 20px;
  padding: 16px;
  border-radius: 8px;
  font-size: 16px;
}

/* Specific classes to avoid clashes */
.geo,
.nameLabel,
.mail {
  margin-bottom: 24px;
}

/* Aligning Labels, Inputs, and Buttons Consistently */
.mint-section label,
.mint-section input,
.mint-section textarea,
.mint-section button,
.map-section label,
.map-section input,
.map-section textarea,
.map-section button {
  width: 100%;
  max-width: 600px; /* Consistent width for form elements */ 
}


.guidelines-section {
  padding: 20px;
  background-color: transparent;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.guidelines-section h3 {
  margin-bottom: 15px;
  color: #50dca1;
}

.guidelines-section ul {
  list-style: none; /* Remove default bullets */
  padding: 0; /* Remove padding */
}

.guidelines-section ul li {
  background: transparent;
  margin: 10px 0;
  padding: 10px;
  text-align: left;
  font-size: 20px; /* Adjust font size */
  line-height: 1.5; /* Improve spacing */
  border-radius: 4px; /* Rounded corners */
  transition: transform 0.2s; /* Add transition for hover effect */
}

.guidelines-section ul li:hover {
  transform: scale(1.02); /* Slightly enlarge on hover */
}

.guidelines-section p {
  margin-top: 15px;
  font-weight: bold;
  color: #ffffff; /* Slightly darker text */
}


/* Mobile styles */
@media (max-width: 600px) {
  .guidelines-section {
    padding: 15px;
  }

  .guidelines-section h3 {
    font-size: 18px; /* Smaller header for mobile */
  }

  .guidelines-section ul li {
    font-size: 14px; /* Smaller font size for mobile */
    padding: 8px; /* Less padding for mobile */
  }

  .guidelines-section p {
    font-size: 14px; /* Smaller paragraph font size */
  }
}

/* Media Queries for Responsiveness */

/* Tablets and smaller devices */
@media only screen and (max-width: 768px) {
  .demo-section {
    padding: 30px;
  }

  .demo-section h2,
  .demo-section h3 {
    font-size: 24px;
  }

  .mint-section input,
  .mint-section textarea,
  .map-section input,
  .map-section textarea {
    padding: 14px; /* Reduced padding for smaller screens */
    font-size: 16px;
    height: 45px; /* Adjust height for smaller screens */
  }

  .mint-section textarea,
  .map-section textarea {
    height: 45px; /* Textarea now matches input height */
  }

  .mint-section button,
  .map-section button {
    padding: 12px 24px;
    font-size: 16px;
    height: 45px; /* Adjust button height */
  }
}

/* Mobile devices (small screens, 600px and below) */
@media only screen and (max-width: 600px) {
  .demo-section {
    padding: 20px;
  }

  .demo-section h2,
  .demo-section h3 {
    font-size: 20px;
  }

  .mint-section input,
  .mint-section textarea,
  .map-section input,
  .map-section textarea {
    padding: 12px; /* Optimized padding for mobile */
    font-size: 14px;
    height: 40px; /* Adjust input height for mobile */
  }

  .mint-section textarea,
  .map-section textarea {
    height: 40px; /* Textarea now matches input height */
  }

  .mint-section button,
  .map-section button {
    width: 100%;
    font-size: 16px;
    height: 40px; /* Consistent button height */
  }

  .alert {
    font-size: 14px;
  }
}

.map-section p { /* Center paragraphs inside the map-section */
  text-align: center;
}
/* Hide the default radio buttons or checkboxes */
.leaflet-control-layers-selector {
  display: none; /* Hide the buttons */
}

/* Make the label (text) clickable and much smaller */
.leaflet-control-layers label {
  cursor: pointer;
  color: black;         /* Set the text color to black */
  font-size: 8px;       /* Make the font even smaller */
  padding: 1px 3px;     /* Reduce padding for the labels */
  margin: 1px 0;        /* Reduce margin between labels */
  display: block;       /* Ensure the entire label is clickable */
  line-height: 1.1;     /* Adjust line-height to reduce spacing */
  position: relative;   /* Positioning context for the pseudo-element */
}

/* Add a black line between labels */
.leaflet-control-layers label:not(:last-child)::after {
  content: "";          /* Empty content for the pseudo-element */
  display: block;       /* Make it a block element */
  height: 1px;         /* Set the height of the line */
  background-color: black; /* Black color for the line */
  margin: 2px 0;       /* Spacing around the line */
}

/* Reduce the width of the control box */
.leaflet-control-layers-expanded {
  width: 90px !important; /* Make the control box narrower */
  background-color: rgb(247, 247, 247); /* Ensure the background color is white */
  color: black;            /* Ensure the text color inside is black */
}

/* Reduce the size of the overall control box */
.leaflet-control-layers {
  font-size: 8px;   /* Further reduce the font size */
  padding: 2px;     /* Decrease padding inside the control */
}

.hidden {
  display: none; /* This will completely hide the element */
}/* Cyan Button Styling */
.cyan-button {
  background-color: #5af0b9; /* Cyan color */
  color: #ffffff; /* White text */
  border: none;
  padding: 0 28px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
  font-size: 18px;
  display: flex; /* Use flexbox for centering */
  align-items: center; /* Center text vertically */
  justify-content: center; /* Center text horizontally */
  text-align: center; /* Ensure text is centered */
  width: 100%;
  max-width: 400px; /* Adjust this width as needed */
  height: 50px; /* Consistent height for inputs */
  margin: 0 auto 24px; /* Center horizontally and add bottom margin */
  text-decoration: none; /* Remove underline for links */
}

/* Center the button container */
.button-container {
  text-align: center; /* Center the button */
  width: 100%; /* Ensure the container spans the full width */
}

/* Hover Effect */
.cyan-button:hover {
  background-color: #50dca1; /* Slightly darker cyan on hover */
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(90, 240, 185, 0.3); /* Light cyan shadow */
}
