.button-28 {
    appearance: none;
    background-color: transparent;
    border: 2px solid var(--color-bg-light-grey );
    border-radius: 10px;
    box-sizing: border-box;
    color: var(--color-bg-white-grey);
    cursor: pointer;
    display: inline-block;
    font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    min-height: 40px;
    min-width: 0;
    outline: none;
    padding: 8px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: transform;
}


.button-28:disabled {
    pointer-events: none;
}

.button-28:hover {
    color: #92cd86;
    background-color: var(--color-bg-light-dark) ;
    box-shadow: #92cd86;
    border: #92cd86;
    transform: translateY(-2px);
}

.button-28:active {
    box-shadow: none;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}


.button-28-green {
    appearance: none;
    background-color: transparent;
    border: 2px solid #0ea300;
    border-radius: 10px;
    box-sizing: border-box;
    color: #0ea300;
    cursor: pointer;
    display: inline-block;
    font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    min-height: 40px;
    min-width: 0;
    outline: none;
    padding: 8px 24px;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: transform;
    -webkit-transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    -moz-transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    -ms-transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    -o-transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
}

.button-28-green:disabled {
    pointer-events: none;
}

.button-28-green:hover {
    color: rgb(0, 0, 0);
    background-color: #0ea300;
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    transform: translateY(-2px);
}

.button-28-green:active {
    box-shadow: none;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
}
