footer {
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: rgba(109, 106, 106, 0.4);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px); */
  background-color: transparent;
  padding: 50px 0 20px 0;
  width: 100%;
  margin: 0 auto;
  margin-top: 100px;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 80%;
}

.left-section {
  flex: 1;
  display: flex;
  align-items: end;
}

.left-section > p {
  font-size: 16px;
}

.right-section {
  flex: 1;
  display: flex;
  justify-content: end;
  gap: 70px;
  padding: 0px 0px 20px 0px;
}

a {
  color: #b6b6b6;
  text-decoration: none;
  transition: color 0.3s ease;
}

a:hover {
  color: #888;
}

.fab {
  font-size: 18px;
}

.footer-header {
  font-weight: 500;
  color: #5af0b9;
}

.socials {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.company-links {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.icon {
  margin-bottom: 3px;
}

@media only screen and (max-width: 750px) {
  .footer-content {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    max-width: 80%;
  }

  .left-section {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .right-section {
    flex: 1;
    display: flex;
    justify-content: center;
    gap: 70px;
  }
}
