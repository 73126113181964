.wallet-title {
  background-color: var(--color-bg-light-dark);
  height: 10rem;
  border-radius: 2rem;
  margin-left: auto;
  margin-right: auto;
  animation: title-glow 1.3s linear infinite;
  -webkit-animation: title-glow 1.3s linear infinite;
  margin-bottom: 250px;
}

.logInMessage {
 display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.wallet-input {
  background-color: transparent;
  border-color: #84ceb0 !important;
  border-top: none;
  outline: none;
  color: white;
}

.inputFields-container{
  display: flex;
    flex-direction: column;
    gap: 15px;
    background: rgba(112, 128, 144,0.4);
    padding: 10px;
    border-radius: 10px;
    backdrop-filter: blur(2px);
    border-top-left-radius: 0px;
}


.wallet-map{
  margin-bottom: 100px;
}

.wallet-key-container{
  display: flex;
  justify-content: space-between;
}

.wallet-public-key-container{
  flex-basis: 75%;
}
.inline-eye{
  display: none;
  margin: 0 10px;
  cursor: pointer;
}
.flex-eye{
  cursor: pointer;
}

.wallet-container{
  max-width: 80%;
  margin: auto;
}

@media only screen and (max-width: 767px) {
  .wallet-title {
    height: 6rem;
    margin-bottom: 100px;
  }
  
  .wallet-map {
    margin-top: 50px;
  }
}

@media only screen and (max-width: 576px) {
  .wallet-title {
    height: 4rem;
    margin-bottom: 50px;
  }
  
  .wallet-map {
    margin-top: 30px;
  }
  .wallet-key-container{
    float: left;
    display: block;
  }
  .inline-eye{
    display: inline-block;
    cursor: pointer;
  }
  .flex-eye{
    display: none;
  }
}

@media only screen and (max-width: 414px) {
  .wallet-title {
    height: 3rem;
    margin-bottom: 30px;
  }
  
  .wallet-map {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .wallet-title {
    height: 2rem;
    margin-bottom: 20px;
  }
  
  .wallet-map {
    margin-top: 15px;
  }
}

@media only screen and (max-width: 320px) {
  .wallet-title {
    height: 1.5rem;
    margin-bottom: 15px;
  }
  
  .wallet-map {
    margin-top: 10px;
  }
}
