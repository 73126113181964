.career-main-container {
  padding-left: 15%;
  padding-right: 15%;
  height: 60vh;
}

.career-heading {
  font-size: 96px;
}

.line {
  width: 100%;
  height: 3px;
  background: #84ceb0;
}

.content-container {
  display: flex;
  gap: 20px;
  margin-top: 39px;
}

.contribution-areas {
  width: 50%;
}

.contribution-areas > p {
  font-weight: 500;
  font-size: 30px;
}

.contribution-areas > ul {
  list-style: none;
}

.contribution-areas > ul li::before {
  content: "\2022";
  color: #84ceb0;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.contribution-areas > ul > li {
  font-weight: 700;
  font-size: 40px;
}

.contribution-description {
  width: 50%;
  font-size: 24px;
  font-weight: 500;
}

.contribution-description > p > span {
  font-weight: 700;
  color: #84ceb0;
}

@media only screen and (max-width: 750px) {
  .career-main-container {
    padding-left: 8%;
    padding-right: 8%;
    height: 90vh;
  }

  .career-heading {
    font-size: 48px;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 39px;
  }

  .contribution-areas {
    width: 100%;
  }

  .contribution-areas > p {
    font-weight: 500;
    font-size: 20px;
  }

  .contribution-areas > ul {
    list-style: none;
  }

  .contribution-areas > ul li::before {
    content: "\2022";
    color: #84ceb0;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  .contribution-areas > ul > li {
    font-weight: 700;
    font-size: 24px;
  }

  .contribution-description {
    width: 100%;
    font-size: 24px;
    font-weight: 500;
  }
}
