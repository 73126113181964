.team-card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: rgba(109, 106, 106, 0.4);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  padding: 20px;
  margin: 20px;
  text-align: left;
  max-width: 300px; /* Set a max width to prevent content from stretching too wide */
}

.inner-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  
}

.inner-container img {
  max-width: 30px;
  height: auto;
  object-fit: contain;
  border-radius: 10px;
}

.inner-container h1 {
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 5px;
  color: #84ceb0;
  font-weight: 700;
}

.team-card h2 {
  font-size: 18px;
  font-weight: 400;
  color: #84ceb0;
}

.about {
  margin: 0;
  color: white;
}

.email {
  margin: 0;
  padding-top: 15px;
  color: #e0e0e0;
  max-width: 300px;
  word-wrap: break-word; /* Break the long email into multiple lines */
  overflow-wrap: break-word; /* Ensures long words are broken if necessary */
  white-space: normal; /* Allow email text to wrap normally */
  font-size: 16px;
}
