  .dialog-content {
    font-size: 16px;
    font-family: Arial, sans-serif;
    color: #000000;
  }
  
  .dialog-button {
    background-color: #000000;
    color: #ffffff;
    font-size: 16px;
    font-family: Arial, sans-serif;
    border: none;
    cursor: pointer;
    margin-top: 10px;
  }

  .dialog {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 4px;
  }
  
  .dialog-header {
    color: #333333;
    font-size: 24px;
    margin-top: 0;
  }

  
  .dialog-error {
    color: #ff0000;
    font-size: 16px;
    margin-bottom: 20px;
  }
  
  .dialog-button {
    background-color: #007bff;
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .dialog-button:hover {
    background-color: #0056b3;
  }


.seed-phrase-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  grid-gap: 30px;
  margin-top: 20px;
}

.seed-phrase-word {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  background-color: rgb(19, 19, 222);
  color: #ffffff;
}