/* Floating Marcy Button */
/* Floating Action Button (FAB) Styles */
.fab-container {
   position: fixed;
   bottom: 10px;
   right: 20px;
   display: flex;
   justify-content: flex-end; z-index: 1000;  
}
.fab-button {
  background-color: #84ceb0;
  color: #000;
  border: none;
  border-radius: 19%;
  width: 76px; /* Set a fixed width for the square */
  height: 65px; /* Set a fixed height for the square */
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.fab-button:hover {
  background-color: #84ceb0; /* Change color on hover */
}
#fab.shrink {
   transform: scale(0.95);
}

/* Chatbot container styles */
.Chatbot {
   position: fixed;
   bottom: 20px;
   right: 20px;
   padding-left: 20px;
   height: 85vh;
   display: flex;
   justify-content: flex-end;
   z-index: 1000;
   opacity: 0;
   transition: opacity 0.1s ease-in-out; /* Fade in and out */
}
/* Make the chatbot visible when chatbotVisible is true */
.Chatbot.visible {
   opacity: 1;
}

/* The container guard of the chatbot */
.Guard {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  padding-right: 105px;
  max-width: 780px;
  min-width: 500px;
}

/* Main chatscope container */
.cs-main-container {
  height: 95vh;
  width: auto;
  background-color: rgba(30, 30, 30, 0.80);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 10px;
  border: none;
}

/* Send Message Button */
.cs-button--send {
   color: #84ceb0;
   background-color: transparent;
   padding-top: 15px;
   padding-bottom: 15px;
}
.cs-button--send:hover {
   color: black;
   background-color: #84ceb0;
}
/* Attachment Button */
.cs-button--attachment {
   color: #84ceb0;
   background-color: transparent;
}
.cs-button--attachment:hover {
   color: black;
   background-color: #84ceb0;
}

.cs-chat-container .cs-message-input {
  background-color: transparent;
  border: none;
  color: white;
  font-size: large;
}

/* Some wrapper for the whole input field container */
.cs-message-input__content-editor-wrapper {
  background-color: transparent;
  color: white;
}

/* Typing indicator dots */
.cs-typing-indicator__indicator {
  color: #84ceb0;
  font-size: large;
  padding-left: 15px;
}

/* Typing indicator container */
.cs-message-list__typing-indicator-container {
  background-color: transparent;
}


/* Marcy text in the typing indicator */
.cs-typing-indicator__text {
  color: #84ceb0;
}

.cs-message-input__content-editor-container {
  background-color: transparent;
  border-style: solid;
  border-color: #84ceb0;
  border-radius: 10px;
  padding: 1.5vh;
  font-color: black;
  font-size: large;
}

/* Attach and Send buttons */
.cs-message-input__tools {
   padding: 0.5em;
   width: 14%;
}

.cs-message-list__typing-indicator-container {
  background-color: transparent;
}

.cs-message-input__content-editor {
  background-color: transparent;
  color: white;
  padding: 3px;
}

.cs-message-input__content-editor[data-placeholder]:empty:before {
  background-color: transparent;
  border-radius: 5px;
  color: rgb(231, 210, 230);
}

/* User chat bubbles */
.cs-message--outgoing {
  padding-top: 25px;
  padding-bottom: 25px;
}
/* Message bubbles */
.cs-message--outgoing .cs-message__content {
  background-color: #84ceb0;
  border-radius: 0.7em;
}

/* All bubbles? */
.cs-message__content {
  background-color: rgb(10, 21, 11);
  color: white;
  font-size: large;
}
/* styles.css */
.body-scroll-disabled {
  overflow: hidden;
}

/* Scrollbar Styles */
.ps__rail-y:hover>.ps__thumb-y, .ps__rail-y:focus>.ps__thumb-y, .ps__rail-y.ps--clicking .ps__thumb-y  {
   background-color: #84ceb0;
   border-radius: 0.7em;
}
/* Scrollbar Rail Styles */
.ps .ps__rail-x:hover, .ps .ps__rail-y:hover, .ps .ps__rail-x:focus, .ps .ps__rail-y:focus, .ps .ps__rail-x.ps--clicking, .ps .ps__rail-y.ps--clicking {
   border-radius: 0.7em;
   opacity: 0.6;
}


.cs-chat-container {
  background-color: transparent;
  width: 100%;
}

.cs-message-list {
  background-color: transparent;
  padding: 1em;
}

.Chatbot-header {
  color: #5af0b9;
  padding: 1rem;
  text-align: center;
  font-size: 3rem;
}


/* Responsive Styles */
@media screen and (max-width: 360px) {
   .cs-main-container {
      width: 60% !important;
   }
   .fab-button {
      width: 40px !important;
      height: 40px !important;
      font-size: 9px !important;
   }
   .Guard {
      height: 90% !important;
   }
}


@media screen and (max-width: 390px) {
   .cs-main-container {
      width: 67% !important;
   }
   .fab-button {
      width: 48px !important;
      height: 46px !important;
   }
   .Guard {
      height: 94% !important;
      padding-left: 10px !important;
   }
}


@media screen and (max-width: 532px) {
   .cs-main-container {
      width: 75%;
   }
   .fab-button {
   }
   .Guard {
      height: 93% !important;
      padding-left: 10px !important;
   }
   .cs-button--send {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
   }
   .cs-message-input__content-editor-wrapper {
      padding: 0px !important;
   }
   .cs-message-input__content-editor-container {
      padding: 5px !important;
   }
}


@media screen and (max-width: 567px) {
   .fab-button {
      width: 40px !important;
      height: 40px !important;
      font-size: 9px !important;
   }

   .cs-main-container {
      height: 45vh;
      padding: 10px;
   }

   .Chatbot {
      bottom: 50px;
      width: 100%;
      align-items: center;
      display: flex;
      justify-content: center;
   }
   .Guard {
     display: flex;
     justify-content: center;
     align-items: center;
     flex-direction: column;
     width: 100%;
   }
}


@media screen and (max-width: 768px) {
  .fab-button {
    width: 55px;
    height: 50px;
    font-size: 13px;
  }
  .cs-main-container {
     height: 80vh;
     max-width: 80%;
     padding: 10px;
  }
  .cs-message-list {
     background-color: transparent;
     padding: 0em;
     padding-bottom: 25px;
  }

  .cs-button--send {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 15px;
      padding-right: 15px;
   }

   .cs-message-input__content-editor-wrapper {
      padding: 0px;
   }

  /* Message input container */
  .cs-message-input__content-editor-container {
      border-width: 1px;
      width: 97%;
      padding: 5px;
  }
  .Guard {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
    max-width: 95%;
  }

  .Chatbot {
    display: flex;
    flex-direction: column;
  }
}
