.leaflet-container{
    height: 70vh;
    background-color: transparent;
    color: rgb(106, 0, 255);
}
.polygon{
    fill: #76eec6;
    stroke: #76eec6;
    stroke-width:3px;
    opacity: 1.0;
}