.team-container {
  margin: 0;
  padding: 0;
  background-color: transparent;
}

header {
  text-align: center;
  padding: 20px;
}

header h1 {
  margin: 0;
  font-size: 80px;
  font-weight: 800;
}

@media screen and (max-width: 768px) {
  .team-card {
    width: 80%;
  }

  header h1 {
    font-size: 45px;
  }
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

@media screen and (max-width: 1199px) {
  .team-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

@media screen and (max-width: 767px) {
  .team-grid {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}
