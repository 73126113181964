* {
  font-size: 20px;
}

.main-container {
  padding-top: 70px;
  padding-left: 15%;
  padding-right: 15%;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  gap: 40px;
}
.pain {
  padding: 0px;
  margin: 0px;
  text-align: none;
}
.heading {
  font-weight: 700;
  margin-left: 0%;
}

.image-style {
  width: 100%;
  height: 100%;
  cursor: pointer;
  grid-column: span 1;
}
.image-style2 {
  width: 100%;
  height: 100%;
  cursor: pointer;
  grid-column: span 2;
}
.expanded {
  cursor: pointer;
}
.image-style-expanded-image {
   position: fixed;
   padding: 30px;
   z-index: 1000;
   height: 100%;
   max-height: 100%;
   max-width: 100%;
}
.image-style2-expanded-image {
   position: fixed;
   padding: 20px;
   z-index: 1000;
   height: auto;
   width: auto;
   max-width: 100%;
   max-height: 100%;
}
.image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.85);
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-box {
  display: grid;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  grid-template-columns: repeat(2, 1fr);
  gap: 28px;
  grid-auto-rows: minmax(100px, auto);
  padding-top: 20px;
  padding-bottom: 20px;
}

@media screen and (max-width: 768px) {
   * {
     font-size: 10px;
   }
   .main-container {
     padding-left: 5%;
     padding-right: 5%;
     word-wrap: break-word;
     width: 80w;
   }
   .image-style-expanded-image {
      position: fixed;
      padding: 30px;
      z-index: 1000;
      height: auto;
      max-height: 100%;
      max-width: 100%;
   }
}

@media screen and (max-width: 480px) {
  .main-container {
    padding-left: 5%;
    padding-right: 5%;
    word-wrap: break-word;
    width: 80vw;
  }
  
}
