body {
  /**-- General --**/
  color: var(--color-font-white) !important;
  box-sizing: border-box;
  background-color: var(--color-bg-dark) !important;
  margin: 0;
  padding:0;
  padding-top: 2px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  /**  General Color  **/
  --color-bg-dark: #121212;
  --color-bg-light-dark: #181818;
  --color-bg-light-grey: #404040;
  --color-bg-dark-grey: #282828;
  --color-bg-white: #FFFFFF;
  --color-bg-white-grey: #b3b3b3;
  --color-bg-element: #101820ff;
  --color-font: var(--color-bg-white-grey);
  --color-light-green: #92cd86;
  --color-font-white: #fff;

}

.mt-200{
  margin-top: 200px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
