h1 {
  color: #5af0b9;
}

.home-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
}

.home-title {
  font-size: 40px;
}
@media screen and (max-width: 991px) {
   .home-title-bg-cl {
      padding-left: 40px;
      padding-top: 40px;
   }
}

.home-problems {
  margin-top: 50vh;
  margin-right: auto;
  margin-left: auto;
}

.home-solves {
  margin: 50vh auto 500px;
}

.home-demonstration {
  margin-right: auto;
  margin-left: auto;
}

video {
  max-width: 80%;
  height: auto;
}
