.box {
  background-color: transparent;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-radius: 1rem;
  height: 65vh;
  margin: 1rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
}

.add {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  background-color: transparent;
  color: white;
  text-align: center;
}

.contact-container {
  font-family: Arial, sans-serif;
  /* background-color: #5AF0B9; */
  color: black;
  justify-content: space-between;
  align-items: flex-start;
  padding: 40px;
  min-height: 70vh;
}

.form-subHeader {
  color: white;
  margin-left: 10px;
}
.contact-form {
  padding: 20px;
  border-radius: 10px;
  margin: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.contact-flex-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  margin: 10px;
}

.contact-flex-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.contact-info {
  color: white;
  padding: 20px;
  border-radius: 10px;
  margin: auto;

  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form-header {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 10px;
  color: #5af0b9;
}

.contact-info .form-header {
  margin-left: 0px;
}

.form-label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-input {
  width: 98%;
  padding: 10px;
  margin: 10px;
  color: #ccc;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  background-color: rgba(109, 106, 106, 0.4);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
}

.submit-btn {
  background-color: #5af0b9;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  align-self: flex-start;
  margin: 10px;
}

.contact-info strong {
  font-size: 16px;
  font-weight: bold;
}

.contact-info div {
  margin-bottom: 0px 10px 10px 10px;
}

@media screen and (min-width: 1530px) {
  .contact-container {
    display: flex;
  }

  .contact-form {
    width: 50%;
  }

  .contact-info {
    width: 50%;
  }

  .form-input {
    min-width: 300px;
  }
}
